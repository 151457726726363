<template>
  <div>

    <div class="card">
      <div class="card-body">
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createPermissionModal" class="btn btn-primary px-5">New Permission</button>
        <pagination @search='loadPermissions' :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Slug</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="permission in permissions" :key="permission.id" data-bs-toggle="modal" data-bs-target="#editPermissionModal" style="cursor: pointer;" @click="currentPermission = permission">
              <td>{{permission.id}}</td>
              <td>{{permission.name}}</td>
              <td>{{permission.slug}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Slug</th>
            </tr>
          </tfoot>
        </table>

        <pagination @search='loadPermissions' :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createPermissionModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Permission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <label for="permissionName" class="form-label">Permission Name</label>
                <input type="text" class="form-control" id="permissionName" v-model="permission.name">
              </div>
              <div class="col-md-12">
                <label for="permissionSlug" class="form-label">Permission Slug</label>
                <input type="text" class="form-control" id="permissionSlug" v-model="permission.slug">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreatePermissionModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createPermission" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editPermissionModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Permission</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <label for="permissionName" class="form-label">Permission Name</label>
                <input type="text" class="form-control" id="permissionName" v-model="currentPermission.name">
              </div>
              <div class="col-md-12">
                <label for="permissionSlug" class="form-label">Permission Slug</label>
                <input type="text" class="form-control" id="permissionSlug" v-model="currentPermission.slug">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeEditPermissionModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="editPermission" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../../components/Pagination.vue';
import SubmitButton from '../../../../components/SubmitButton.vue';
export default {
  name: 'admin.management.permissions',
  components: {
    Pagination,
    SubmitButton
  },
  data(){
    return {
      permissions: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0
      },
      creating: false,
      permission: {
        name: '',
        slug: ''
      },
      currentPermission: {
        id: -1,
        name: '',
        slug: ''
      }
    }
  },
  mounted(){
    this.loadPermissions();
  },
  methods: {
    loadPermissions(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/permissions?page=${page}`)
      .then(response => {
        this.permissions = response.data.permissions;
        this.pagination = response.data.pagination
      })
      .catch(error => {
        this.$error("Failed to load permissions.", error);
      });
    },
    createPermission(){
      if(this.permission.name.length < 1){
        this.$error("Please enter a valid name!");
        return;
      }
      if(this.permission.slug.length < 1){
        this.$error("Please enter a valid slug!");
        return;
      }
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/permissions`, this.permission)
      .then(() => {
        $('#closeCreatePermissionModal').click();
        this.$success("Created new permission");
        this.creating = false;
        this.permission = {
          name: "",
          slug: ""
        }
        this.loadPermissions(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to create new permission", error);
        this.creating = false;
      });
    },
    editPermission(){
      if(this.currentPermission.name.length < 1){
        this.$error("Please enter a valid name!");
        return;
      }
      if(this.currentPermission.slug.length < 1){
        this.$error("Please enter a valid slug!");
        return;
      }
      this.creating = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/permissions/${this.currentPermission.id}`, this.currentPermission)
      .then(() => {
        $('#closeEditPermissionModal').click();
        this.$success("Updated permission");
        this.creating = false;
        this.loadPermissions(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to update permission", error);
        this.creating = false;
      });
    }
  }
}
</script>